/* @license
 *
 * © 2017–2024 Daniel Aleksandersen <https://www.daniel.priv.no/>
 * SPDX-License-Identifier: CC0-1.0
 */
(function() {
  function change_color_scheme(color_scheme)
  {
    if (!['dark', 'light'].includes(color_scheme))
    {
      return;
    }
    let color_meta = document.querySelector('meta[name="color-scheme"]');
    if (color_meta)
    {
      color_meta.content = color_scheme;
    }
    let theme_color_meta_light = document.querySelector('meta[name="theme-color"][data-theme-color-scheme="light"]');
    let theme_color_meta_dark = document.querySelector('meta[name="theme-color"][data-theme-color-scheme="dark"]');
    if (color_scheme == 'dark')
    {
      document.documentElement.classList.add('color-scheme-dark')
      document.documentElement.classList.remove('color-scheme-light');
      if (theme_color_meta_dark)
      {
        theme_color_meta_dark.media = 'all';
      }
      if (theme_color_meta_light)
      {
        theme_color_meta_light.media = 'none';
      }
    }
    else if (color_scheme == 'light')
    {
      document.documentElement.classList.add('color-scheme-light')
      document.documentElement.classList.remove('color-scheme-dark');
      if (theme_color_meta_dark)
      {
        theme_color_meta_dark.media = 'none';
      }
      if (theme_color_meta_light)
      {
        theme_color_meta_light.media = 'all';
      }
    }
  }
  let saved_color_scheme = localStorage.getItem('user-color-scheme');
  if (saved_color_scheme)
  {
    change_color_scheme(saved_color_scheme);
  }
  let main_skip_button = document.querySelector('#head .masthead .skip-main');
  if (main_skip_button)
  {
    function on_color_scheme_toggle(ev)
    {
      let color_scheme = ev.target.checked ? 'dark' : 'light';
      localStorage.setItem('user-color-scheme', color_scheme);
      change_color_scheme(color_scheme);
    }
    let color_scheme_label = document.createElement('label');
    color_scheme_label.classList.add('color-scheme-toggle-label');
    color_scheme_label.title = "Light/dark theme toggle"
    let color_scheme_toggle = document.createElement('input');
    color_scheme_toggle.classList.add('color-scheme-toggle');
    color_scheme_toggle.type = 'checkbox';
    if (saved_color_scheme == 'dark' || (saved_color_scheme != 'light' && window.matchMedia('(prefers-color-scheme:dark)').matches))
    {
      color_scheme_toggle.checked = true;
    }
    color_scheme_toggle.addEventListener('change', on_color_scheme_toggle);
    color_scheme_label.appendChild(color_scheme_toggle);
    main_skip_button.after(color_scheme_label);
  }
  function chromiumRepaintSVGforDarkMode()
  {
    if ((navigator.userAgentData && navigator.userAgentData.brands && navigator.userAgentData.brands.some(function(brand) {return brand.brand == 'Chromium'}) ) ||navigator.userAgent.includes('Chromium') || navigator.userAgent.includes('Chrome'))
    {
      window.matchMedia('(prefers-color-scheme:dark)').addEventListener('change', function (ev)
      {
        [].forEach.call(document.querySelectorAll('img[src$=".svg"]'), function (el) {
          var src = el.src;
          el.src = 'data:image/svg+xml,%3Csvg/%3E';
          setTimeout(function()
          {
            el.src = src;
          }, 0);
        });
      });
    }
  }
  chromiumRepaintSVGforDarkMode();
  function enforceMinimumRootMarginForLazyElementLoading()
  {
    if ('IntersectionObserver' in window)
    {
      var lazy_elements = document.querySelectorAll('img[loading="lazy"],iframe[loading="lazy"]');
      if (lazy_elements.length <= 0)
      {
        return;
      }
      function lazyIntersectionHandler(observed, observer)
      {
        [].forEach.call(observed, function(o) {
          if (o.isIntersecting)
          {
            observer.unobserve(o.target);
            if (o.target && !o.target.complete)
            {
              o.target.loading = 'auto';
            }
          }
        });
      }
      var opts = {
        root: null,
        rootMargin: '1400px 1400px',
        threshold: 0.0
      }
      var observer = new IntersectionObserver(lazyIntersectionHandler, opts);
      [].forEach.call(lazy_elements, function(element) {
        observer.observe(element);
      });
    }
  }
  enforceMinimumRootMarginForLazyElementLoading();
})();